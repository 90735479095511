// import {
//   REGISTER_REQUEST,
//   REGISTER_SUCCESS,
//   REGISTER_FAILURE,
//   LOGIN_REQUEST,
//   LOGIN_SUCCESS,
//   LOGIN_FAILURE,
//   FETCH_CUSTOMERS_REQUEST,
//   FETCH_CUSTOMERS_SUCCESS,
//   FETCH_CUSTOMERS_FAILURE,
//   GET_USER_REQUEST,
//   GET_USER_SUCCESS,
//   GET_USER_FAILURE,
//   LOGOUT,
// } from "./ActionTypes";

// const initialState = {
//   user: null,
//   isLoading: false,
//   error: null,
// };

// const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case REGISTER_REQUEST:
//     case LOGIN_REQUEST:
//       return { ...state, isLoading: true, error: null };
//     case REGISTER_SUCCESS:
//       return { ...state, isLoading: false };
//     case REGISTER_FAILURE:
//     case LOGIN_FAILURE:
//       return { ...state, isLoading: false, error: action.payload };
//     case LOGIN_SUCCESS:
//       return { ...state, isLoading: false };
//     case GET_USER_REQUEST:
//       return { ...state, isLoading: true, error: null };
//     case GET_USER_SUCCESS:
//       return { ...state, isLoading: false, user: action.payload };
//     case GET_USER_FAILURE:
//       return { ...state, isLoading: false, error: action.payload };
//       case LOGOUT:
//         localStorage.removeItem("jwt");
//         return { ...state, jwt: null, user: null };
//     default:
//       return state;
//   }
// };

// export default authReducer;

import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  LOGOUT,
} from "./ActionTypes";

// Initial state for the auth reducer
const initialState = {
  customers: [],
  user: null,
  isLoading: false,
  error: null,
};

// Auth reducer to handle authentication-related actions
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle register and login request actions
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
    case GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    // Handle register success action
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    // Handle login success action
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    // Handle register and login failure actions
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    // Handle get user success action
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    // Handle logout action
    case LOGOUT:
      localStorage.removeItem("jwt");
      return {
        ...state,
        user: null,
      };

    // Default case returns the current state
    default:
      return state;
  }
};

export default authReducer;
