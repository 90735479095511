// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import { Avatar, CardHeader, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";

const rows = [
  {
    age: 27,
    status: "current",
    date: "09/27/2018",
    name: "Sally Quinn",
    salary: "$19586.23",
    email: "eebsworth2m@sbwire.com",
    designation: "Human Resources Assistant",
    image:
      "https://rukminim1.flixcart.com/image/832/832/xif0q/lehenga-choli/c/v/q/free-half-sleeve-sadhna-kedar-fab-original-imagpawdqwjqz6vt.jpeg?q=70",
  },
  {
    age: 61,
    date: "09/23/2016",
    salary: "$23896.35",
    status: "professional",
    name: "Margaret Bowers",
    email: "kocrevy0@thetimes.co.uk",
    designation: "Nuclear Power Engineer",
    image:
      "https://rukminim1.flixcart.com/image/832/832/xif0q/lehenga-choli/c/v/q/free-half-sleeve-sadhna-kedar-fab-original-imagpawdqwjqz6vt.jpeg?q=70",
  },
  {
    age: 59,
    date: "10/15/2017",
    name: "Minnie Roy",
    status: "rejected",
    salary: "$18991.67",
    email: "ediehn6@163.com",
    designation: "Environmental Specialist",
    image:
      "https://rukminim1.flixcart.com/image/832/832/xif0q/lehenga-choli/c/v/q/free-half-sleeve-sadhna-kedar-fab-original-imagpawdqwjqz6vt.jpeg?q=70",
  },
  {
    age: 30,
    date: "06/12/2018",
    status: "resigned",
    salary: "$19252.12",
    name: "Ralph Leonard",
    email: "dfalloona@ifeng.com",
    designation: "Sales Representative",
    image:
      "https://rukminim1.flixcart.com/image/832/832/xif0q/lehenga-choli/c/v/q/free-half-sleeve-sadhna-kedar-fab-original-imagpawdqwjqz6vt.jpeg?q=70",
  },
  {
    age: 66,
    status: "applied",
    date: "03/24/2018",
    salary: "$13076.28",
    name: "Annie Martin",
    designation: "Operator",
    email: "sganderton2@tuttocitta.it",
    image:
      "https://rukminim1.flixcart.com/image/832/832/xif0q/lehenga-choli/c/v/q/free-half-sleeve-sadhna-kedar-fab-original-imagpawdqwjqz6vt.jpeg?q=70",
  },
  {
    age: 33,
    date: "08/25/2017",
    salary: "$10909.52",
    name: "Adeline Day",
    status: "professional",
    email: "hnisius4@gnu.org",
    designation: "Senior Cost Accountant",
  },
  {
    age: 61,
    status: "current",
    date: "06/01/2017",
    salary: "$17803.80",
    name: "Lora Jackson",
    designation: "Geologist",
    email: "ghoneywood5@narod.ru",
  },
  {
    age: 22,
    date: "12/03/2017",
    salary: "$12336.17",
    name: "Rodney Sharp",
    status: "professional",
    designation: "Cost Accountant",
    email: "dcrossman3@google.co.jp",
  },
];

const Customers = () => {
  const navigate = useNavigate();
  function handlePaginationChange(event, value) {
    console.log("Current page:", value);
  }
  return (
    <Box>
      <Card>
        <CardHeader
          title="All Customers"
          sx={{
            pt: 2,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 390 }} aria-label="table in dashboard">
            <TableHead>
              <TableRow>
                <TableCell>User Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(0, 10).map((item, index) => (
                <TableRow
                  hover
                  key={item.name}
                  sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {" "}
                    <Avatar alt={item.name} src={item.image} />{" "}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card className="mt-2 felx justify-center items-center">
        <Pagination
          className="py-5 w-auto"
          size="large"
          count={10}
          color="primary"
          onChange={handlePaginationChange}
        />
      </Card>
    </Box>
  );
};

export default Customers;

// Customers.js

// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import Table from '@mui/material/Table';
// import TableRow from '@mui/material/TableRow';
// import TableHead from '@mui/material/TableHead';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import { Avatar, CardHeader, Pagination, CircularProgress, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { fetchCustomers } from '../../../Redux/Auth/Action'; // Import the fetchCustomers action

// const Customers = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { customers, isLoading, error } = useSelector((store) => store.customer); // Adjust the state path if needed
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const rowsPerPage = 10; // Number of rows per page

//   // Fetch customers from the API using the action
//   useEffect(() => {
//     dispatch(fetchCustomers());
//   }, [dispatch]);

//   // Handle pagination change
//   const handlePaginationChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   // Calculate the customers to display based on the current page
//   const displayedCustomers = customers.slice(
//     (currentPage - 1) * rowsPerPage,
//     currentPage * rowsPerPage
//   );

//   // return (
//   //   <Box>
//   //     {/* Customers Card */}
//   //     <Card>
//   //       <CardHeader
//   //         title='All Customers'
//   //         sx={{
//   //           pt: 2,
//   //           alignItems: 'center',
//   //           '& .MuiCardHeader-action': { mt: 0.6 },
//   //         }}
//   //       />

//   //       {/* Show loading spinner or error message */}
//   //       {isLoading ? (
//   //         <Box display="flex" justifyContent="center" alignItems="center" p={3}>
//   //           <CircularProgress />
//   //         </Box>
//   //       ) : error ? (
//   //         <Typography color="error" align="center" p={3}>
//   //           {error}
//   //         </Typography>
//   //       ) : (
//   //         <TableContainer>
//   //           <Table sx={{ minWidth: 390 }} aria-label='customers table'>
//   //             <TableHead>
//   //               <TableRow>
//   //                 <TableCell>User Id</TableCell>
//   //                 <TableCell>Image</TableCell>
//   //                 <TableCell>Name</TableCell>
//   //                 <TableCell>Email</TableCell>
//   //               </TableRow>
//   //             </TableHead>
//   //             <TableBody>
//   //               {displayedCustomers.map((customer, index) => (
//   //                 <TableRow
//   //                   hover
//   //                   key={customer.id} // Assuming each customer has a unique 'id'
//   //                   sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}
//   //                 >
//   //                   <TableCell>{index + 1 + (currentPage - 1) * rowsPerPage}</TableCell>
//   //                   <TableCell>
//   //                     <Avatar alt={customer.name} src={customer.image || ''} />
//   //                   </TableCell>
//   //                   <TableCell>{customer.name}</TableCell>
//   //                   <TableCell>{customer.email}</TableCell>
//   //                 </TableRow>
//   //               ))}
//   //             </TableBody>
//   //           </Table>
//   //         </TableContainer>
//   //       )}
//   //     </Card>

//   //     {/* Pagination Card */}
//   //     <Card className="mt-2 flex justify-center items-center">
//   //       <Pagination
//   //         className="py-5 w-auto"
//   //         size="large"
//   //         count={Math.ceil(customers.length / rowsPerPage)}
//   //         color="primary"
//   //         page={currentPage}
//   //         onChange={handlePaginationChange}
//   //       />
//   //     </Card>
//   //   </Box>
//   // );
//   return (
//     <Box>
//       <Card>
//         <CardHeader
//           title='All Customers'
//           sx={{ pt: 2, alignItems: 'center', '& .MuiCardHeader-action': { mt: 0.6 } }}
//         />
//         <TableContainer>
//           <Table sx={{ minWidth: 390 }} aria-label='table in dashboard'>
//             <TableHead>
//               <TableRow>
//                 <TableCell>User Id</TableCell>
//                 <TableCell>Image</TableCell>
//                 <TableCell>Name</TableCell>
//                 <TableCell>Email</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {customers.slice(0, 10).map((user, index) => (
//                 <TableRow key={user._id} hover sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>
//                     <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.image || ""} />
//                   </TableCell>
//                   <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Card>
//       <Card className="mt-2 flex justify-center items-center">
//         <Pagination
//           className="py-5 w-auto"
//           size="large"
//           count={10}
//           color="primary"
//           onChange={handlePaginationChange}
//         />
//       </Card>
//     </Box>
//   );
// };

// export default Customers;
